<template>
  <v-container class="white px-0" fluid>
    <v-card flat>
      <region-form :item="item" @save="save" />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import RegionForm from "./RegionForm";

export default {
  name: "RegionEditor",
  components: { RegionForm },
  data() {
    return {
      item: {},
    };
  },
  computed: {
    ...mapGetters("regions", ["getRegionById"]),

    idParam() {
      return parseInt(this.$route.params.regionId);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  mounted() {
    // in case user navigated directly to edit view load target item only
    if (this.editMode) {
      const item = this.getRegionById(this.idParam);
      if (item) {
        this.item = item;
      } else {
        this.loadRegions().then(() => {
          this.item = this.getRegionById(this.idParam);
        });
      }
    }
  },
  methods: {
    ...mapActions("regions", ["loadRegions", "saveRegion"]),

    save(params) {
      const { item, editMode } = params;
      this.saveRegion({ item, editMode }).then(() => {
        this.$router.go(-1);
      });
    },
    getItemDto(item) {
      return { ...item, categoryId: item.category ? item.category.id : null };
    },
  },
};
</script>
